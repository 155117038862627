import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './lazyloader.module.scss';

const LazyContent = ({ children, wait, className }) => {
  return (
    <>
      {typeof wait !== 'undefined' ? (
        children
      ) : (
        <span className={classNames(styles.defaultPlaceholder, className)} />
      )}
    </>
  );
};

LazyContent.propTypes = {
  children:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default LazyContent;
