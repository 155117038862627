exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".NewsCarouselContentBlock__NewsCarousel_slider-content{position:relative}.NewsCarouselContentBlock__NewsCarousel_slider-content .NewsCarouselContentBlock__NewsCarousel_navigation{height:100%}.NewsCarouselContentBlock__NewsCarousel_slider-content .NewsCarouselContentBlock__NewsCarousel_navigation button{background-color:#fff;background-repeat:no-repeat;border:none;cursor:pointer;overflow:hidden;outline:none;width:40px}.NewsCarouselContentBlock__NewsCarousel_slider-content .NewsCarouselContentBlock__NewsCarousel_navigation button.NewsCarouselContentBlock__NewsCarousel_next,.NewsCarouselContentBlock__NewsCarousel_slider-content .NewsCarouselContentBlock__NewsCarousel_navigation button.NewsCarouselContentBlock__NewsCarousel_prev{position:absolute;top:calc(20% - 20px)}.NewsCarouselContentBlock__NewsCarousel_slider-content .NewsCarouselContentBlock__NewsCarousel_navigation button.NewsCarouselContentBlock__NewsCarousel_next{right:-11px}.NewsCarouselContentBlock__NewsCarousel_slider-content .NewsCarouselContentBlock__NewsCarousel_navigation button.NewsCarouselContentBlock__NewsCarousel_prev{left:-11px}.NewsCarouselContentBlock__NewsCarousel_slider-content .NewsCarouselContentBlock__NewsCarousel_navigation button .NewsCarouselContentBlock__NewsCarousel_loader{border-radius:50%;border:3px solid #f1f1f1;border-top-color:#d6d6d6;width:18px;height:18px;animation:NewsCarouselContentBlock__NewsCarousel_spin 2s linear infinite}.NewsCarouselContentBlock__NewsCarousel_slider-content .NewsCarouselContentBlock__NewsCarousel_navigation button:disabled img{opacity:.5}.NewsCarouselContentBlock__NewsCarousel_slider-content .NewsCarouselContentBlock__NewsCarousel_navigation .NewsCarouselContentBlock__NewsCarousel_navigation-icon{height:100%;width:100%}@keyframes NewsCarouselContentBlock__NewsCarousel_spin{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);

// exports
exports.locals = {
	"slider-content": "NewsCarouselContentBlock__NewsCarousel_slider-content",
	"sliderContent": "NewsCarouselContentBlock__NewsCarousel_slider-content",
	"navigation": "NewsCarouselContentBlock__NewsCarousel_navigation",
	"next": "NewsCarouselContentBlock__NewsCarousel_next",
	"prev": "NewsCarouselContentBlock__NewsCarousel_prev",
	"loader": "NewsCarouselContentBlock__NewsCarousel_loader",
	"spin": "NewsCarouselContentBlock__NewsCarousel_spin",
	"navigation-icon": "NewsCarouselContentBlock__NewsCarousel_navigation-icon",
	"navigationIcon": "NewsCarouselContentBlock__NewsCarousel_navigation-icon"
};