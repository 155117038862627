import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Tile.module.scss';
import { Link, ResponsiveLazyImage } from 'sana/elements';
import { newsItemPage } from 'sana/routes';
import { renderHTML } from 'sana/utils';
import { UseSanaTexts } from 'sana/texts';
import { LazyContent } from '../../utils/lazyLoader';
import { setNewsHtml, formatDate } from '../../utils/helpers';
import { TextKeys } from '../../utils/textKeysConstants';

const NewsTile = ({ model, modelSettings }) => {
  const { publishedDate, featuredImage } = modelSettings.news;
  /* istanbul ignore next */
  const to = useMemo(() => newsItemPage(model.id), [model]);
  return (
    <div className={classNames(styles.newsContent, 'news-carousel-news-content', model.id === undefined && styles.contentLoading)}>
      <div className={styles.newsDetails}>
        <div className={styles.newsInner}>
          <LazyContent
            className={styles.imageLoader}
            wait={model.url}
          >
            <Link url={model.url} to={to} tabIndex="0" aria-label={model.title}>
              {featuredImage && (
                <div
                  className={classNames(
                    styles.imgWrp,
                    model.image && !model.image.src ? styles.noImage : null,
                    'news-carousel-news-content',
                  )}
                >
                  <LazyContent
                    className={classNames(styles.imageLoader, styles.noImage)}
                    wait={model.image && model.image.src ? 1 : undefined}
                  >
                    {model.image && model.image.src ? (
                      <ResponsiveLazyImage
                        src={model.image.src}
                        alt={model.image.alt ? model.image.alt : model.title}
                        className={styles.image}
                        title={model.title}
                        draggable="false"
                      />
                    ) : null}
                  </LazyContent>
                </div>
              )}
            </Link>
          </LazyContent>

          <div className={styles.newsTitle}>
            <LazyContent
              className={styles.imageLoader}
              wait={model.title}
            >
              <Link url={model.url} to={to} tabIndex="0" title={model.title}  aria-label={model.title}>
                <h3>{model.title}</h3>
              </Link>
            </LazyContent>
          </div>
          {publishedDate && (
            <div className={styles.newsDate}>
              <LazyContent
                className={styles.imageLoader}
                wait={model.date ? 1 : undefined}
              >
                {formatDate(model.date)}
              </LazyContent>
            </div>
          )}
          <div className={styles.newsDetail}>
            <LazyContent
              className={styles.imageLoader}
              wait={model.shortDescription}
            >
              <>
                {renderHTML(
                  setNewsHtml(model),
                )}
                <Link url={model.url} to={to} tabIndex="0" title={model.title} className={styles.readMore}>
                  <span>
                    <UseSanaTexts textKeys={TextKeys}>
                      {text => text[1]}
                    </UseSanaTexts>
                  </span>
                </Link>
              </>
            </LazyContent>
          </div>
        </div>
      </div>
    </div>
  );
};
NewsTile.propTypes = {
  model: PropTypes.object,
  modelSettings: PropTypes.object,
};
export default NewsTile;
