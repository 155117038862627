import '../assets/slick.scss';
import '../assets/slick-theme.scss';
import styles from './NewsCarousel.module.scss';
import './NewsCarousel.scss';
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { connect, shallowEqual, useDispatch } from 'react-redux';
import { requestNews } from '../behavior/actions';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import {
  useLoadEffect,
  useMatchMediaContext,
} from 'sana/utils';
import { InfoAlert } from 'sana/elements';
import classNames from 'classnames';
import {
  getDefaultNewsList,
  getPageInfo,
  pageMetaSettings,
  sliderMetaSettings,
} from '../utils/constants';
import { featuredImageMapping } from '../utils/handleEvent';
import { TextKeys } from '../utils/textKeysConstants';
import { UseSanaTexts } from 'sana/texts';
import LeftArrow from './customArrows/LeftArrow';
import RightArrow from './customArrows/RightArrow';
import NewsTile from './newsTile/Tile';

const NewsCarousel = ({ model, id, news, expired, requestNews }) => {
  const mediaContext = useMatchMediaContext();
  const carousel = useRef(null);
  const modelRef = useRef();
  const dispatch = useDispatch();
  const elementId = `news-carousel-content-block-${id}`;
  let modelExpired = false;
  if (modelRef.current && !shallowEqual(modelRef.current, model))
    modelExpired = true;

  modelRef.current = model;
  let pageInfo = getPageInfo(mediaContext, model);

  if (modelExpired) {
    pageInfo = getPageInfo(mediaContext, model);
  }

  const [pageMeta, setpageMeta] = useState(pageMetaSettings(pageInfo));
  const [sliderMeta, setSliderMeta] = useState(sliderMetaSettings);
  const [newsList, setNewsList] = useState(getDefaultNewsList(pageInfo));

  const sliderRef = useRef();

  const nextCallback = () => {
    nextAction();
    sliderRef.current.slickNext();
  };

  const prevCallback = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: pageInfo.itemPerRow,
    slidesToScroll: pageInfo.itemPerRow,
    afterChange: indexOfCurrentSlide => {
      setSliderMeta({
      ...sliderMeta,
      currentSlideIndex: indexOfCurrentSlide,
    });
    },
  };
  const requestNewsData = useCallback(
    (id, perPageItem, currentPage, init = false) => {
      dispatch(requestNews(id, currentPage, perPageItem));
    },
    [modelExpired, pageMeta],
  );

  const resetStates = useCallback(() => {
    setpageMeta(pageMetaSettings(pageInfo));
    setSliderMeta(sliderMetaSettings);
  }, [modelExpired, expired]);

  const nextAction = debounce(() => {
    if(newsList.length <= pageMeta.totalCount) {
      const isHitAPI = pageMeta.requesthit < pageMeta.totalCount;
   
      setpageMeta({
        ...pageMeta,
        currentPage: pageMeta.nextPage,
        isLoading: isHitAPI,
        requesthit: isHitAPI
          ? pageMeta.requesthit + pageMeta.perPageItem
          : pageMeta.requesthit,
      });
      if (isHitAPI) {
        requestNewsData(id,pageMeta.perPageItem, pageMeta.nextPage);
      }
    } 
  }, 400);

  useEffect(() => {
    if (news) {
      setpageMeta({
        ...pageMeta,
        totalCount: news.totalCount,
        isLoading: false,
        numberOfPage: Math.round(
          parseInt(news.totalCount) / pageInfo.itemPerRow,
        ),
        nextPage: pageMeta.nextPage + 1,
      });
      setSliderMeta({
        ...sliderMeta,
        disabledPrev: false,
        hideNavigation: pageMeta.perPageItem >= pageMeta.totalCount,
      });
      const exitingNews = newsList.filter(item => item.id !== undefined);
      const unOderData = exitingNews.concat(news.items);
      setNewsList(()=>uniqBy(unOderData, e => e.id));
    }
  }, [news]);

  useLoadEffect(() => {
    resetStates();
    requestNewsData(id, pageMeta.perPageItem, pageMeta.currentPage, true);
    setSliderMeta({
      ...sliderMeta,
    });
  }, [id,expired, modelExpired]);

  const getRender = getNewData => {
    let element = [];
    for (let index = sliderMeta.index; index < getNewData.length; index++) {
      getNewData[index] = {
        ...getNewData[index],
        image: featuredImageMapping(getNewData[index]),
      };
      element = element.concat(
        <NewsTile key={index} model={getNewData[index]} modelSettings={model} />,
      );
    }
    return element;
  };

  const getNewsList = useMemo(() => {
    return getRender(
      !newsList?.length ? (news ? news.items : []) : newsList,
    );
  }, [news, newsList]);

  return (
    <div
      id={elementId}
      className={classNames('news-carousel-content-block')}
      ref={carousel}
    >
      { getNewsList.length > 0 ?  (
        <div className={styles.sliderContent}>
          <Slider ref={slider => (sliderRef.current = slider)} {...settings}>
            {getNewsList}
          </Slider>
          <div className={styles.navigation}>
            <LeftArrow
              imagePath={model.carousal.previousButtonIcon}
              disabled={sliderMeta.currentSlideIndex === 0}  
              onClick={prevCallback}
            />
            <RightArrow
              imagePath={model.carousal.nextButtonIcon}
              disabled={sliderMeta.currentSlideIndex === (pageMeta.totalCount - pageInfo.itemPerRow)}
              onClick={nextCallback}
            />
          </div>
        </div> 
        ) : (
        <UseSanaTexts textKeys={TextKeys}>{text => (
          <InfoAlert>
            {text[0]}
          </InfoAlert>
        )}
      </UseSanaTexts>
      )}
    </div>
  );
};
NewsCarousel.propTypes = {
  model: PropTypes.object,
  id: PropTypes.string.isRequired,
  news: PropTypes.object,
  expired: PropTypes.bool,
  requestNews: PropTypes.func.isRequired,
};

const mapStateToProps = ({ news }, { id }) => {
  const getNews = news && news[id];
  if (!getNews) {
    return { expired: undefined };
  }

  return {
    news: getNews.list,
    expired: getNews.expired,
  };
};

export default connect(mapStateToProps, { requestNews })(NewsCarousel);
