export const newsModel = {
    id: undefined,
    url: undefined,
    image:undefined,
    title: undefined,
    date:undefined,
    mainDescription: undefined,
    shortDescription: undefined,
  };
  export const getDefaultNewsList = Row => {
    const list = [];
    for (let i = 0; i < Row.itemPerRow; i++) {
      list.push(newsModel);
    }
    return list;
  };
  
  export const noOfRows = 1;
  
  export const getPageInfo = (mediaContext, model) => {
    const { xs, sm, md, lg, xl } = mediaContext;
    const isSSR = typeof window === 'undefined';
    
    const {
        extraLargeScreens,
        largeScreens,
        mediumScreens,
        smallScreens,
        extraSmallScreens,
    } = model.layout;
    let itemPerRow = extraLargeScreens;
    let itemColSize = null;
    if (xl) {
      itemPerRow = extraLargeScreens;
      itemColSize = 100 / extraLargeScreens;
    } else if (lg || isSSR) {
      itemPerRow = largeScreens;
      itemColSize = 100 / largeScreens;
    } else if (md) {
      itemPerRow = mediumScreens;
      itemColSize = 100 / mediumScreens;
    } else if (sm) {
      itemPerRow = smallScreens;
      itemColSize = 100 / smallScreens;
    } else if (xs) {
      itemPerRow = extraSmallScreens;
      itemColSize = 100 / extraSmallScreens;
    }
    const pageSize = itemPerRow * noOfRows;
    return { pageSize, itemPerRow, itemColSize };
  };

  export const pageMetaSettings = pageInfo => ({
    currentPage: 0,
    nextPage: 0,
    numberOfPage: 0,
    perPageItem: pageInfo.itemPerRow * 2,
    totalCount: 0,
    isLoading: false,
    requesthit: pageInfo.itemPerRow * 2,
  });

  export const sliderMetaSettings = {
    index: 0,
    disabledNext: false,
    disabledPrev: true,
    currentSlideIndex: 0,
    hideNavigation: false,
  };