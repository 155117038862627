const regexGetImages = /<img [^>]*src="[^"]*"[^>]*>/gm;
//eslint-disable-next-line
const regexSrc = /src\=([^\s]*)\s/;
//eslint-disable-next-line
function getImageMeta(image) {
  const imageMeta = {
    src: null,
    alt: null,
  };
  imageMeta.alt = image.split('alt=')[1]
    ? image.split('alt=')[1].replace(/['>"]+/g, '')
    : null;
  const srcWithQuotes = image.match(regexSrc)[1];
  imageMeta.src = image
    .match(regexSrc)[1]
    .substring(1, srcWithQuotes.length - 1);
  return imageMeta;
}
export const featuredImageMapping = newsItem => {
  const { shortDescription, mainDescription } = newsItem;

  if (shortDescription && shortDescription.match(regexGetImages)) {
    return getImageMeta(shortDescription.match(regexGetImages)[0]);
  } else if (mainDescription && mainDescription.match(regexGetImages)) {
    return getImageMeta(mainDescription.match(regexGetImages)[0]);
  }
  return null;
};
