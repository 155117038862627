import { NEWS_RECEIVED } from './actions';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'sana/events';

const initialState = {
    news: {},
};
const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case NEWS_RECEIVED:
            const { contentBlockId, news } = payload;
            return {
                ...state,
                news: {
                    ...state.news,
                    [contentBlockId]: news,
                },
            };

        case VIEWER_CHANGED:
            return onViewerChanged(state);

        case LANGUAGE_CHANGED:
            return onLanguageChanged(state);

        default:
            return state;
    }
    /* istanbul ignore next */
    function onViewerChanged(state) {
        return { ...state, blocks: undefined };
    }
    /* istanbul ignore next */
    function onLanguageChanged(state) {
        if (!state.news)
            return state;

        return {
            ...state,
            news: Object.entries(state.news).reduce((news, [key, value]) => {
                news[key] = { ...value, expired: true };
                return news;
            }, {}),
        };
    }
};

export default reducer;